
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          $margin_top_bottom_container: 29px;
$margin_top_form_container: 29px;
$max_height_credit_recharge_info_wrapper: 56px;
$max_height_credit_wrapper: 172px;
$height_cta_button: 48px;

.createProject {
  position: relative;
  display: flex;
  width: calc(100% - #{$WIDTH_SNB});
  height: 100%;

  .panel {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 20px 0 16px 0;
    gap: 24px;

    > .topContainer {
      width: 100%;
      height: calc(100% - #{$margin_top_bottom_container} - #{$height_cta_button} - 24px);

      > .titleContainer {
        display: flex;
        align-items: center;
        padding: 0 24px 0;

        > .iconBtn {
          span[role="img"] {
            color: var(--icon-default);
          }
        }
      }

      .formContainer {
        @include custom-scroll-bar;
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        flex-flow: column nowrap;
        height: calc(100% - #{$margin_top_form_container} - 6px);
        padding: 0 24px 0;
        gap: 24px;

        .form {
          display: flex;
          flex-flow: column nowrap;
          @keyframes show-section {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          animation: show-section 0.4s ease-in-out;

          .fieldLabelContainer {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
          }
          .fieldLabel {
            @include body2-regular;
            display: flex;
            height: 28px;
            color: var(--text-main);
            gap: 2px;

            span[role="img"] {
              font-size: 16px;
              align-self: flex-start;
              cursor: help;
              color: var(--icon-secondary);
            }
          }
          .switchContainer {
            position: relative;

            .switch {
              align-self: flex-start;
              margin-top: 3px;
            }
            > .loading {
              position: absolute;
              top: -1.5px;
              left: 6px;
            }
          }

          > :global(.ods-input-field-wrapper) {
            width: initial;
          }

          > .analysisTypeContainer {
            display: flex;
            flex-flow: column nowrap;
            gap: 14px;

            > .serviceContainer {
              display: flex;
              flex-flow: column nowrap;
              gap: 12px;

              > .label {
                @include body2-medium;
                color: var(--text-guide);
              }
            }
          }
          .buttonContainer {
            display: flex;
            gap: 8px;

            > button, :global(.ods-tooltip-children-wrapper) button {
              @include body2-regular;
              display: flex;
              align-items: center;
              width: fit-content;
              height: 40px;
              padding: 6px 14px 6px 10px;
              cursor: pointer;
              border: 2px solid var(--border-divider);
              border-radius: 10px;
              background: var(--bg-sub);
              gap: 2px;

              > img {
                width: 18px;
                height: 18px;
              }
              > span[role="img"] {
                font-size: 20px;
              }
            }
            > button:hover, :global(.ods-tooltip-children-wrapper) button:not(:disabled):hover {
              border-color: var(--border-active);
            }
            > .active, :global(.ods-tooltip-children-wrapper) .active {
              border-color: var(--border-active);
              background: var(--planet-blue-50);

              > span[role="img"] {
                color: var(--icon-active);
              }
            }
            > button:disabled, button[disabled] {
              cursor: not-allowed;
              background: var(--static-200);

              > span[role="img"] {
                color: var(--icon-disabled);
              }
              > p {
                color: var(--text-guide);
              }
            }
          }
          .buttonContainer.grid {
            display: grid;
            gap: 8px;
            grid-template-columns: repeat(2, 1fr);

            > button, :global(.ods-tooltip-children-wrapper), :global(.ods-tooltip-children-wrapper) button {
              width: 100%;
              gap: 6px;
            }
          }
          .buttonContainer + .infoBox {
            margin-top: 12px;
          }
          .buttonContainer + .sourceItem {
            margin-top: 16px;
          }

          .infoBox {
            display: flex;
            width: 100%;
            padding: 12px;
            border: 1px solid var(--blue-200);
            border-radius: 8px;
            background: var(--blue-50);
            gap: 8px;

            > span[role="img"] {
              font-size: 18px;
              align-self: flex-start;
              color: var(--blue-500);
            }
            > p {
              @include body2-regular;
              white-space: pre-wrap;
            }
          }

          .sourceItem {
            .rightTool {
              align-self: center;
              height: var(--comp-xs);
            }
          }
        }

        .divider {
          display: flex;
          border-bottom: 1px solid var(--border-divider);
        }
      }

      .titleContainer + .formContainer {
        margin-top: $margin_top_form_container;
      }
    }
    .isCreditBoundaryVisible {
      height: calc(100% - #{$margin_top_bottom_container} - #{$max_height_credit_wrapper} - #{$height_cta_button});
    }
    .creditRechargeInfoWrapperVisible {
      height: calc(
        100% - #{$margin_top_bottom_container} - #{$max_height_credit_recharge_info_wrapper} - #{$max_height_credit_wrapper} - #{$height_cta_button} - 24px
      );
    }
    .isPrivateDomains {
      height: calc(
        100% - #{$margin_top_bottom_container} - #{$max_height_credit_recharge_info_wrapper} - 24px - #{$height_cta_button} - 24px
      );
    }

    .bottomContainer {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      padding: 0 24px 0;
      gap: 24px;

      > .creditInfoBox {
        display: flex;
        justify-content: space-between;
        max-height: $max_height_credit_recharge_info_wrapper;
        padding: 8px 8px 8px 16px;
        cursor: pointer;
        border: 1px solid var(--red-200);
        border-radius: var(--radius-medium);
        background: var(--red-50);

        > .left {
          display: flex;
          gap: 8px;

          span[role="img"] {
            font-size: 32px;
            color: var(--semantic-danger);
          }
          > .content {
            @include body2-regular;
            @include multi-line-ellipsis(2);
            margin-left: 8px;
            text-align: left;
            white-space: pre-wrap;
            color: var(--text-main);
          }
        }
        > .right {
          display: flex;
          height: 100%;

          span[role="img"] {
            font-size: 24px;
            color: var(--icon-secondary);
          }
        }
      }
      .creditInfoBox:hover {
        border: 1px solid var(--semantic-danger);
      }

      > .creditContainer {
        display: flex;
        flex-flow: column nowrap;
        padding: 16px 20px;
        border: 1px solid var(--border-divider);
        border-radius: var(--radius-medium);

        > .content {
          @include body2-regular;
          display: flex;
          justify-content: space-between;
          color: var(--text-main);
        }
        .danger {
          color: var(--semantic-danger);
        }
        .info {
          color: var(--semantic-info);
        }

        > .divider {
          height: 1px;
          margin: 12px 0;
          background: var(--border-divider);
        }

        > .content + .content {
          margin-top: 8px;
        }
      }

      > .ctaButton {
        width: 100%;
        height: $height_cta_button;
      }
    }
  }

  .myImagesPanel {
    position: absolute;
    z-index: $Z_INDEX_POPOVER;
    left: $WIDTH_PANEL;
    width: $WIDTH_PANEL;
    height: 100%;
  }
}
