
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .header {
  @include heading2;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--text-main);

  span[role="img"] {
    color: var(--icon-default);
  }
}
