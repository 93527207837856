
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .settingsContainer {
  position: absolute;
  z-index: $Z_INDEX_MODAL;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.08);

  .settings {
    @include custom-scroll-bar;
    position: relative;
    display: flex;
    overflow-x: auto;
    width: 1192px;
    height: 80%;
    border-radius: var(--radius-medium);
    background: white;

    .sideBar {
      display: flex;
      flex-flow: column nowrap;
      width: 256px;
      padding: 21px 12px;
      border-radius: var(--radius-medium) 0 0 var(--radius-medium);
      background: var(--bg-sub);
      gap: 4px;

      .menuButton {
        width: 100%;
        height: 40px;

        span {
          @include body2-semi-bold;
          color: var(--text-sub);
        }
        span[role="img"] {
          font-size: var(--icon-medium);
          color: var(--icon-secondary);
        }

        &:hover {
          background: var(--comp-hover-blue);
        }
      }
      .active,
      .active:hover {
        background: var(--comp-select);
        span {
          color: var(--comp-primary);
        }
        span[role="img"] {
          color: var(--icon-active);
        }
      }
    }

    .menuContent {
      @include custom-scroll-bar;
      position: relative;
      display: flex;
      overflow-y: auto;
      flex-flow: column nowrap;
      width: 936px;
      padding: 28px 48px 40px 48px;

      > .title {
        @include heading2;
        margin-bottom: 24px;
        color: var(--text-main);
      }

      > .content {
        display: flex;
        flex-flow: column nowrap;
        margin-top: 8px;

        > .contentItem {
          display: flex;
          justify-content: space-between;

          > .left {
            display: flex;
            flex-flow: column nowrap;

            > .title {
              @include body1-regular;
              color: var(--text-main);
            }

            > .subTitle {
              @include heading3;
              margin-bottom: 24px;
              color: var(--text-main);
            }

            > .desc {
              @include caption1-regular;
              color: var(--text-sub);
            }
            .title + .desc {
              margin-top: 8px;
            }
          }

          .dropdown {
            width: 200px;
            border: 1px solid var(--border-default);
          }
        }

        > .contentList {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          gap: 24px;
        }
      }
    }

    .closeButton {
      position: absolute;
      top: 24px;
      right: 40px;

      span[role="img"] {
        color: var(--icon-default);
      }
    }
  }
}
