
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .payment {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  .logo {
    width: 219px;
    margin-top: 80px;
    object-fit: cover;
  }

  .section {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 560px;
    padding: 40px 64px;
    border-radius: 8px;
    background: var(--white-color);

    > .title {
      @include heading2;
      width: 100%;
    }
    > .horizontal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > .subTitle {
        @include body1-semi-bold;
      }
      > .desc {
        @include body1-regular;
        margin-left: 16px;
        color: var(--text-sub);
      }
      > .totalPrice {
        @include heading3;
      }
      > .primary {
        color: var(--comp-primary);
      }

      .desc + .desc {
        margin-top: 8px;
      }
    }
    > .divider {
      width: 100%;
      height: 1px;
      margin: 24px 0;
      background: var(--border-divider);
    }

    .paymentWidget {
      width: 100%;
    }
    .payBtn {
      width: 100%;
    }

    .title + .horizontal {
      margin-top: 30px;
    }
    .horizontal + .subTitleContainer {
      margin-top: 22px;
    }
    .title + .paymentWidget {
      margin-top: 20px;
    }
  }

  .logo + .section {
    margin-top: 56px;
  }
  .section + .section {
    margin-top: 24px;
  }
}