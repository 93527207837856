
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .popover {
  position: absolute;
  z-index: $Z_INDEX_POPOVER;
  display: flex;
  flex-flow: column nowrap;
  width: 144px;
  padding: 8px 0;
  border: 1px solid var(--border-divider);
  border-radius: var(--radius-medium);
  background: var(--bg-main);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);

  > li {
    @include body2-medium;
    @include one-line-ellipsis;
    height: 32px;
    padding: 6px 8px 6px 16px;
    cursor: pointer;
    color: var(--text-main);

    &:hover {
      background: var(--comp-hover-blue);
    }
  }

  > .danger {
    color: var(--semantic-danger);
  }
  > .disabled {
    cursor: not-allowed;
    color: var(--text-disabled);
  }
}
