
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .projectInfoMap {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  > .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 16px 16px;
    border-bottom: 1px solid var(--border-divider);
    background: var(--bg-main);
    box-shadow: initial;

    > .left {
      display: flex;

      .title {
        @include one-line-ellipsis;
        @include body2-semi-bold;
      }
    }

    > .right {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .mapContainer {
    position: relative;
    width: 100%;
    height: 100%;

    :global(.ol-swipe:before) {
        left: calc(50% + 1px);
        background: var(--static-50);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
    }

    :global(.ol-swipe) {
    height: 100%;
    background: transparent;

    button:before {
        margin-right: 1px;
        content: url("../../asset/icons/ArrowBack.png");
    }

    button {
        position: absolute;
        top: 50%;
        left: -28px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: var(--static-50);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    }

    button:after {
        margin-left: 1px;
        content: url("../../asset/icons/ArrowForward.png");
    }

    button:before,
    button:after {
        position: initial;
        width: initial;
        background: transparent;
    }

    button:hover,
    button:focus {
        outline: 1px solid var(--icon-secondary);
    }
    }

    :global(.ol-swipe:after) {
    @include body1-semi-bold;
    top: 0;
    bottom: 0;
    left: 4px;
    width: 120px;
    height: 32px;
    padding: 4px 25px;
    content: var(--superx);
    text-align: center;
    color: var(--white-color);
    background: rgba(0, 0, 0, 0.5);
    }

    :global(.tile-load-progress) {
      position: absolute;
      z-index: 1;
    }

    .customScaleLine {
      @include body2-regular;
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      height: 20px;
      padding: 0 4px;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);

      .olScaleLine {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 20px;
        padding: 3px 0;
        justify-self: flex-end;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 15px;
          color: var(--white-color);
          border: 1px solid white;
          border-top: none;
          background: none;

          will-change: contents, width;
        }
      }
    }
  }

  .projectInfo {
    height: calc(100% - #{$HEIGHT_GNB});

    .controlCard.left {
      left: 24px;
    }
    .controlCard.right {
      right: 24px;
    }
  }

  .images {
    :global(.ol-zoom),
    :global(.mapBtn) {
      right: calc(#{$WIDTH_PANEL} + #{$RIGHT_MAP_TOOL}) !important;
    }
  }

  .resultExportPopover.images {
    right: calc(#{$WIDTH_PANEL} + 16px) !important;
  }
}

  