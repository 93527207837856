
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .sideBarNavigation {
  display: flex;
  flex-flow: column nowrap;

  a {
    @include body2-medium;
    display: flex;
    align-items: center;
    width: 220px;
    height: 40px;
    padding: 8px 24px;
    color: var(--text-main);
    border-radius: var(--radius-medium);

    &:hover {
      background-color: var(--planet-blue-50);
    }
  }

  .active {
    background-color: var(--planet-blue-50);
  }
}
