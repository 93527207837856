
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .pricing {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  .header {
    align-self: flex-start;
  }

  .headCopy {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    margin-top: 160px;

    > .title {
      font-size: 48px;
      font-weight: 700;
      font-style: normal;
      line-height: 60px;
      letter-spacing: -2.4px;
    }
    > .desc {
      @include heading1;
      margin-top: 24px;
      color: var(--planet-blue-500);
    }
  }

  .planList {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    margin-top: 100px;
    gap: 36px;
  }

  .aiPackTable {
    width: 100%;
    min-width: 1280px;
    margin-top: 148px;
  }

  .aiPackTableCaptionWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .aiPackTableCaption {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      line-height: 28px;
      margin-top: 24px;
      text-align: right;
      letter-spacing: -0.01em;
      color: var(--text-sub);
    }
  }

  .globeWrapper {
    overflow: hidden;
    align-items: flex-start;
    width: 812px;
    height: 300px;
    margin-top: 148px;

    .globeImg {
      width: 812px;

      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }
}

.pricing.isSettingsMenu {
  .headCopy {
    margin-top: 0;

    > .title {
      font-size: 32px;
      letter-spacing: -1.6px;
    }
    > .desc {
      @include heading2;
      margin-top: 8px;
    }
  }
  .planList {
    margin-top: 40px;
    gap: 20px;
  }
  .aiPackTable {
    min-width: initial;
    margin-top: 79px;

    :global(thead.ant-table-thead th) {
      @include body1-regular;
    }
    :global(td.ant-table-cell) {
      @include body1-semi-bold;
    }
  }
  .aiPackTableCaption {
    @include body1-regular;
  }
}

@include mobile-media-query {
  .pricing {
    .headCopy {
      .title {
        font-size: 44px;
        padding: 0 14px;
        text-align: center;
        word-break: keep-all;
      }

      .desc {
        font-size: 18px;
        padding: 0 14px;
      }
    }
  }
}

@include tablet-media-query {
  .pricing {
    .planList {
      width: 94%;
    }
  }
}

//table용 media query
@media (max-width: 1279px) {
  .pricing {
    width: 100%;

    .aiPackTable {
      min-width: fit-content;
    }

    .globeWrapper {
      width: 100%;

      .globeImg {
        width: 100%;
      }
    }
  }
}

@media (max-width: 469px) {
  [class~="tariffs"] {
    display: none;
  }

  .aiPackTable {
    colgroup {
      col:last-child {
        display: none;
      }
    }
  }
}
