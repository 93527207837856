
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .selectBucketModal {
  width: 744px;

  section {
    height: 464px;
  }

  .myConnectionsTable {
    :global(.ant-table-tbody) > :global(tr.ant-table-row:hover) > td,
    :global(.ant-table-tbody) > tr > :global(td.ant-table-cell-row-hover) {
      cursor: pointer;
    }
  }
}
