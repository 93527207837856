
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .footNavigationBar {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  min-width: fit-content;
  height: 404px;
  background-color: var(--white-color);

  > .navigation {
    display: flex;
    align-items: center;
    margin-top: 80px;

    > .iconContainer {
      display: flex;
      align-items: flex-start;
      flex-flow: column nowrap;
      gap: 8px;

      .logoContainer {
        display: flex;
        align-items: center;
        gap: 8px;

        .logo {
          display: flex;
          height: 28px;
          object-fit: cover;
        }
        .logoMainText {
          font-size: 20px;
          font-weight: 600;
          font-style: normal;
          line-height: 24px; /* 120% */
          color: var(--text-main);
        }
        .logoSubText {
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          line-height: 24px; /* 150% */
          color: var(--text-main);
        }
      }

      > .frameWrapper {
        display: flex;
        margin-top: 72px;

        > .frame {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: var(--grey-200);

          > img {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }

        .frame + .frame {
          margin-left: 8px;
        }
      }
    }

    > .siteMapGroupWrapper {
      display: flex;
      margin-left: 138px;
      gap: 40px;

      > .siteMapWrapper {
        display: flex;
        align-items: flex-start;
        flex-flow: column nowrap;
        width: 180px;

        > .title {
          @include heading3;
          color: var(--text-main);
        }
        > .menu {
          @include body1-regular;
          display: flex;
          height: 24px;
          color: var(--text-sub);
        }

        > .title + .menu {
          margin-top: 16px;
        }
        > .menu + .menu {
          margin-top: 8px;
        }
      }
    }
  }

  > .copyRight {
    @include caption1-regular;
    margin-top: 95px;
    margin-bottom: 81px;
    color: var(--grey-500);
  }
}

@include tablet-media-query {
  .footNavigationBar {
    > .navigation {
      margin-top: 60px;

      .iconContainer {
        justify-content: space-between;
        height: 100%;

        .frameWrapper {
          margin-top: 0;
        }
      }
      .siteMapGroupWrapper {
        display: grid;
        width: 100%;
        margin-left: 118px;
        grid-template-columns: repeat(3, 1fr);
        gap: 52px;

        .siteMapWrapper {
          width: 100%;

          .menu {
            display: none;
          }
        }
      }
    }

    .copyRight {
      margin: 60px 0;
    }
  }
}
@include min-tablet-media-query {
  .footNavigationBar {
    > .navigation {
      flex-flow: column nowrap;
      margin-top: 40px;

      .iconContainer {
        align-items: center;
        flex-flow: row nowrap;
        gap: 4px;

        .logoContainer {
          .logo {
            height: 24px;
          }
        }

        .frameWrapper {
          margin-top: 0;
          margin-left: 16px;
        }
      }
      .siteMapGroupWrapper {
        display: grid;
        align-items: flex-start;
        width: 100%;
        margin-top: 40px;
        margin-left: 0;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);

        .siteMapWrapper {
          width: 80%;

          .menu {
            display: none;
          }
        }
      }
    }

    .copyRight {
      margin: 44px 0;
    }
  }
}