
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .aiPackTitleImage {
  display: flex;
  overflow: hidden;
  flex-flow: column nowrap;
  width: 43.8vw;
  max-width: 756px;
  height: 600px;
  border: 1px solid var(--grey-300);
  border-radius: var(--radius-medium);
  background-color: var(--grey-100);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.12);
  object-fit: cover;

  > .header {
    display: flex;
    align-items: center;

    > .control {
      display: flex;
      margin-left: 27px;
      gap: 8px;
      .close {
        width: 12px;
        height: 12px;
        border: 1px solid #da4038;
        border-radius: 50%;
        background: #fe5c51;
      }

      .minimize {
        width: 12px;
        height: 12px;
        border: 1px solid #d9a657;
        border-radius: 50%;
        background: #fbb739;
      }

      .expand {
        width: 12px;
        height: 12px;
        border: 1px solid #0a9d2b;
        border-radius: 50%;
        background: #1bc040;
      }
    }

    > .caption {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 19px;
      display: flex;
      align-items: center;
      height: 63px;
      margin-left: 29px;
      color: var(--grey-800);

      > p {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }

  > .img {
    height: 537px;
    object-fit: cover;
  }
}

@include tablet-media-query {
  .aiPackTitleImage {
    width: 40vw;
  }
}
@include mobile-media-query {
  .aiPackTitleImage {
    width: 100%;
    max-width: 100%;
    height: 300px;

    > .header {
      > .control {
        margin-left: 0;

        .close, .minimize, .expand {
          display: none;
        }
      }
      > .caption {
        height: 44px;
      }
    }
    > .img {
      height: 100%;
    }
  }
}