
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .analysisFilter {
  top: 112px;
  right: calc(-#{$WIDTH_FILTER_POPOVER} - 15px);

  .periodFilter {
    .fieldLabel {
      margin-bottom: 8px;
    }
  }

  > form {
    display: flex;
    flex-flow: column nowrap;

    .formItem {
      display: flex;
      flex-flow: column nowrap;

      .fieldLabel {
        @include body2-regular;
        display: flex;
        padding-bottom: 8px;
      }
    }

    [class~="ods-checkbox-wrapper"] {
      display: flex;
      padding: 4px;
    }

    .service {
      .aiPack {
        margin-left: 8px;
      }
    }
  }
}
