
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .baseMap {
  display: flex;
  width: calc(100% - #{$WIDTH_SNB});
  height: 100%;

  /* BaseMap default style - Start */
  .mapContainer {
    position: relative;
    width: calc(100% - #{$WIDTH_PANEL});
    height: 100%;

    :global(.tile-load-progress) {
      position: absolute;
      z-index: 1;
    }

    .customScaleLine {
      @include body2-regular;
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      height: 20px;
      padding: 0 4px;
      color: white;
      background: rgba(0, 0, 0, 0.5);

      .olScaleLine {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        justify-self: flex-end;
        height: 20px;
        padding: 3px 0;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 15px;
          color: var(--white-color);
          border: 1px solid white;
          border-top: none;
          background: none;

          will-change: contents, width;
        }
      }
    }
  }
  /* BaseMap default style - End */
}
