
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .layout {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;

  .profileContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 99px;

    > div {
      display: flex;
      width: 40px;
      height: 40px;

      :global(.ods-badge),
      :global(.ods-avatar) {
        width: 40px;
        height: 40px;
      }
    }

    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .profilePopover {
      top: 50%;
      left: 50%;
    }
  }

  .helpContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .helpPopover {
      bottom: 50%;
      left: 50%;
    }
  }

  .sideNavigationBarExpanded {
    position: absolute;
    z-index: $Z_INDEX_EXPANDED_GNB;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 256px;
    height: 100%;
    transition: margin-left 0.3s ease-in-out;
    border-right: 1px solid var(--border-divider);
    background: var(--bg-main);

    .top {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      width: 100%;
      padding-top: 12px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 8px 0 20px;

        .ovisionLogo {
          font-size: 150px;
          height: 36px;
        }
        span[role="img"] {
          color: var(--icon-secondary);
        }
      }

      .profileContainer {
        display: flex;
        align-items: center;
        width: calc(100% - 16px);
        height: 56px;
        margin-top: 8px;
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 8px;
        gap: 12px;

        &:hover {
          background: var(--comp-hover-blue);
        }

        .profileDetail {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          .name {
            @include body2-medium;
            @include one-line-ellipsis;
            align-self: flex-start;
            color: var(--text-main);
          }

          .credit {
            display: flex;
            align-self: flex-start;
            margin-top: 4px;
            gap: 4px;

            span[role="img"] {
              font-size: 16px;
              color: var(--icon-secondary);
            }
            .creditText {
              @include caption1-regular;
              @include one-line-ellipsis;
              user-select: none;
              color: var(--text-sub);
            }
          }
        }

        .profilePopover {
          transform: translate(84px, 5px);
        }
      }

      .divider {
        display: flex;
        width: calc(100% - 24px);
        height: 1px;
        margin: 4px 0 8px;
        background: var(--border-divider);
      }

      .navigationMenu {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
        gap: 8px;

        > a {
          display: flex;
          width: 100%;
        }
        .menuButton {
          display: flex;
          width: 232px;
          height: 39px;
          margin: 0 12px;
          padding: 8px 8px 8px 16px;

          span {
            @include body2-semi-bold;
            color: var(--text-sub);
          }
          span[role="img"] {
            font-size: var(--icon-medium);
            color: var(--icon-secondary);
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      width: calc(100% - 16px);
      margin-bottom: 16px;

      .menuButton {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 6px 20px 6px 12px;
        gap: 4px;

        span {
          @include body2-semi-bold;
          color: var(--text-sub);
        }
        span[role="img"] {
          font-size: var(--icon-medium);
          color: var(--icon-secondary);
        }

        &:hover {
          background: initial;
        }
      }

      .helpContainer {
        width: 100%;
        border-radius: 8px;

        .helpPopover {
          transform: translate(84px, 5px);
        }

        &:hover {
          background: var(--comp-hover-blue);
        }
      }
    }

    .active {
      .menuButton {
        background: var(--comp-select);
        span[role="img"] {
          color: var(--icon-active) !important;
        }
        span {
          color: var(--comp-primary) !important;
        }
      }
    }
  }
  .sideNavigationBarExpanded.animation {
    margin-left: -256px;
    transition: margin-left 0.3s ease-in-out;
  }

  .sideNavigationBar {
    z-index: $Z_INDEX_EXPANDED_GNB - 1;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: $WIDTH_SNB;
    height: 100%;
    border-right: 1px solid var(--border-divider);
    background: var(--bg-main);

    .top {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      width: 100%;
      padding-top: 12px;

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .navigationMenu {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 12px;

        .active {
          button {
            background-color: var(--comp-select);
          }

          span[role="img"] {
            color: var(--icon-active);
          }
        }
      }

      > .profileContainer {
        margin-top: 16px;
      }
      > .profileContainer + :global(.ods-tooltip-children-wrapper) {
        margin-top: 24px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    span[role="img"] {
      color: var(--icon-secondary);
    }

    :global(.ods-icon-button-square[disabled]) {
      > span[role="img"] {
        color: var(--icon-disabled);
      }
    }
  }

  .dimmed {
    position: fixed;
    z-index: $Z_INDEX_EXPANDED_GNB - 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.08);
  }
}
