
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .protectedLayout {
  display: flex;
  width: 60.2vw;
  max-width: 1040px;
  padding-top: 80px;

  > nav {
    margin-right: 48px;
  }
}

@include tablet-media-query {
  .protectedLayout {
    align-items: flex-start;
    flex-direction: column;
    width: 80vw;
    padding-top: 40px;

    nav {
      margin-right: 0;
      margin-bottom: 48px;
    }
  }
}
