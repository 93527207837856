
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .imageItem {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  min-height: 120px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-divider);

  .highlight {
    position: absolute;
    left: -4%;
    display: none;
    width: 108%;
    height: 100%;
    border-radius: var(--radius-medium);
  }

  :global(.ods-thumbnail) {
    height: 96px;
  }
  .thumbnail {
    width: 96px;
    height: 96px;
    background: var(--grey-900);
    object-fit: contain !important;
  }
  .empty {
    background: transparent;
  }

  > .contents {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: calc(100% - 96px - 12px);
    margin-left: 12px;

    > .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      :global(.ods-tooltip-children-wrapper) {
        @include one-line-ellipsis;
      }
      .title {
        @include one-line-ellipsis;
        @include body2-semi-bold;
      }

      > .statusIcon {
        font-size: var(--icon-medium);
        margin-left: 10px;
      }
    }

    > .region,
    .satellite,
    .date {
      @include one-line-ellipsis;
      @include body2-regular;
    }

    > .date {
      color: var(--text-sub);
    }

    > .contextPopoverContainer {
      position: absolute;
      right: 0;
      bottom: 0;

      .positionClassName {
        position: absolute;
        top: 18px;
        // TODO: 오른쪽으로 배치
        //left: 14px;
        left: -127px;
      }
      .contextMenu {
        //position: fixed;
      }
      .contextMenuBtn {
        display: none;
      }
    }

    > .titleContainer + span {
      margin-top: 3px;
    }
    > span + span {
      margin-top: 3px;
    }
  }

  &:hover {
    .highlight {
      display: flex;
      background: var(--comp-hover-blue);
    }
    .contextPopoverContainer {
      .contextMenuBtn {
        display: flex;
      }
    }
  }
}

.active,
.active:hover {
  .highlight {
    display: flex;
    background: var(--comp-select);
  }
}

.disabled,
.disabled:hover {
  cursor: not-allowed;
  color: var(--text-disabled);

  .highlight {
    display: none;
  }
  .date {
    color: var(--text-disabled) !important;
  }
}

:global(.ant-tooltip-placement-bottomRight .ant-tooltip-arrow) {
  right: 2px;
}
