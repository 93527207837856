
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .panelPagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: $WIDTH_PANEL;
  height: $HEIGHT_PANEL_PAGINATION;
  padding-right: 24px;
  border: 1px solid var(--border-divider);
  border-left: 0;
  background: var(--bg-main);

  > span {
    @include body2-regular;
    margin-right: 16px;
    color: var(--text-main);
  }
}
