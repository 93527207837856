
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .myAccount {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-bottom: 300px;

  .titleContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    > .title {
      @include heading1;
      margin-bottom: 8px;
    }
    > .title.isSettingsMenu {
      @include heading2;
      margin-bottom: 24px;
    }

    > .desc {
      @include body1-medium;
      color: var(--text-sub);
    }
  }

  .profileContainer {
    display: flex;
    width: 100%;
    margin-top: 24px;

    .profile {
      font-size: 32px;
      width: 80px;
      height: 80px;
    }
    > .accountContainer {
      display: flex;
      flex-flow: column nowrap;
      width: calc(100% - 80px - 32px);
      margin-left: 32px;
      gap: 24px;

      .content {
        display: flex;
        flex-flow: column nowrap;
        gap: 8px;

        .label {
          @include body2-regular;
        }
        .data {
          @include body1-semi-bold;
        }
      }
      .horizontal {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        gap: 12px;

        > .content {
          width: 50%;
        }
      }
    }
  }

  .menuContainer {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin-top: 64px;
    gap: 16px;

    .labelContainer {
      display: flex;
      flex-flow: column wrap;
      gap: 4px;

      > .title {
        @include heading3;
        color: var(--text-main);
      }
      > .desc {
        @include body2-regular;
        color: var(--text-sub);
      }
    }

    > button {
      width: fit-content;
    }
    > .deleteBtn {
      color: var(--semantic-danger);
      border: 1px solid var(--semantic-danger);

      &:hover,
      &:focus {
        color: var(--red-600);
        border: 1px solid var(--red-600);
      }
    }
  }
}
