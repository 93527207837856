
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .paymentLayout {
  @include custom-scroll-bar;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  max-width: 100%;
  max-height: 100%;
  background: var(--bg-main);

  .closeBtn {
    position: fixed;
    top: 28px;
    right: 28px;

    span[role="img"] {
      font-size: var(--icon-large);
      color: var(--icon-secondary);
    }
  }
}