
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          // TODO: LegalContentsEnglish.module.scss와 통합
.legal {
  display: flex;
  flex-flow: column nowrap;

  .label {
    @include heading3;
    color: var(--text-guide);
  }

  .headingOne {
    font-family: "Pretendard";
    font-size: 40px;
    font-weight: 800;
    font-style: normal;
    line-height: 40px;
    color: var(--text-main);
  }
  .headingTwo {
    font-family: "Pretendard";
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    line-height: 32px;
    color: var(--text-main);
  }
  .headingThree {
    @include heading2;
    color: var(--text-main);
  }

  .desc {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
    white-space: pre-wrap;
    color: var(--text-main);
  }

  table {
    tr {
      border-bottom: 1px solid var(--border-divider);
    }
    th {
      @include caption1-medium;
      padding: 6px 0;
      text-align: left;
      color: var(--text-sub);
    }
    td {
      @include caption1-medium;
      padding: 10px 0;
      color: var(--text-main);
    }
    .grey {
      color: var(--text-sub);
    }
    .underline {
      text-decoration-line: underline;
    }
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
  }

  .label + .headingOne {
    margin-top: 12px;
  }
  .headingTwo + .desc {
    margin-top: 16px;
  }
  .headingTwo + .headingThree {
    margin-top: 24px;
  }
  .headingThree + .desc,
  .headingThree + table {
    margin-top: 8px;
  }
  .headingOne + .desc,
  table + .headingThree,
  .desc + .headingThree {
    margin-top: 32px;
  }
  .headingOne + .content,
  .desc + .content,
  .content + .content {
    margin-top: 64px;
  }
}
