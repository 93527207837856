
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .projectItem {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  min-height: 96px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-divider);

  .highlight {
    position: absolute;
    left: -4%;
    display: none;
    width: 108%;
    height: 100%;
    border-radius: var(--radius-medium);
  }

  > .iconWrapper {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--comp-xl);
    height: var(--comp-xl);
    border-radius: var(--radius-medium);
    background: var(--bg-sub);
  }

  > .contents {
    position: relative;
    flex-flow: column nowrap;
    width: calc(100% - var(--comp-xl) - 12px);
    margin-left: 12px;

    > .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .title {
        @include one-line-ellipsis;
        @include body2-semi-bold;
      }

      > .statusIcon {
        font-size: var(--icon-medium);
        margin-left: 10px;

        .loading {
          width: 24px;
          height: 24px;

          .loadingInner {
            width: calc(100% - 8px);
            height: calc(100% - 8px);
          }
        }
      }
    }

    > .chip {
      margin: 4px 0;
    }

    .descContainer {
      display: flex;
      align-items: center;

      > span {
        @include body2-regular;
        white-space: pre;
        color: var(--text-sub);
      }
      .realtime.inProgress {
        color: var(--earth-green-600);
      }
      .realtime.paused {
        color: var(--moonlight-yellow-600);
      }
      span[role="img"] {
        margin: 0 5px;
        color: var(--icon-default);
      }
      .desc + .desc {
        @include one-line-ellipsis;
      }
    }

    > .contextPopoverContainer {
      position: absolute;
      right: 0;
      bottom: 0;

      .positionClassName {
        position: absolute;
        top: 18px;
        // TODO: 오른쪽으로 배치
        //left: 14px;
        left: -127px;
      }
      .contextMenu {
        //position: fixed;
      }
      .contextMenuBtn {
        display: none;
      }
    }
  }

  &:hover {
    .highlight {
      display: flex;
      background: var(--comp-hover-blue);
    }
    .contextPopoverContainer {
      .contextMenuBtn {
        display: flex;
      }
    }
  }
}

.active,
.active:hover {
  .highlight {
    display: flex;
    background: var(--comp-select);
  }
}
