
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .refundModal {
  section {
    .paymentInfoContainer {
      display: flex;
      flex-flow: column nowrap;
      gap: 10px;

      .subTitle {
        @include body1-semi-bold;
      }
      .label {
        @include body2-regular;
      }
      .data {
        @include body2-semi-bold;
      }
      .primary {
        color: var(--comp-primary);
      }
    }

    .horizontal {
      display: flex;
      justify-content: space-between;
    }
    .divider {
      height: 1px;
      margin: 24px 0;
      border: none;
      background: var(--border-divider);
    }

    .guide {
      li:not(.strong) {
        @include caption1-regular;
        color: var(--text-guide);
      }
      .strong {
        @include caption1-semi-bold;
        white-space: pre-wrap;
      }
    }
  }
}