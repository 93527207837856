
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .myConnections {
  display: flex;
  flex-flow: column nowrap;

  .subTitle {
    @include heading3;
    display: flex;
    color: var(--text-main);
  }

  .myConnectionsTable {
    :global(.ant-table-tbody > tr > td) {
      height: 60px;
    }

    :global(.ant-table-tbody > tr.ant-table-row:hover > td),
    :global(.ant-table-tbody > tr > td.ant-table-cell-row-hover) {
      background: var(--comp-hover-blue);
    }
    :global(.ant-table-tbody > tr.my-connections-table-row-disabled:hover > td),
    :global(.ant-table-tbody > tr.my-connections-table-row-disabled > td.ant-table-cell-row-hover) {
      cursor: not-allowed !important;
      background: transparent;
    }
    :global(.ant-table-tbody > tr.my-connections-table-row-disabled p) {
      color: var(--text-disabled) !important;
    }

    .loading {
      margin: -20px !important;
    }

    .connectionCell {
      display: flex;
      align-items: center;

      img {
        width: 32px !important;
        height: 32px !important;
        margin-right: 16px;
      }
      .label {
        .cloud {
          @include caption1-regular;
        }
        .bucket {
          @include body2-regular;
          color: var(--text-main);
        }
        p + p {
          margin-top: 2px;
        }
      }
    }
    .statusCell {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .positionClassName {
        position: absolute;
        // TODO: 오른쪽으로 배치
        //right: -130px;
        right: 13px;
        bottom: 28px;
        width: 158px;
      }
      .contextMenu {
        //position: fixed;
      }
      .contextMenuBtn {
        span[role="img"] {
          color: var(--icon-default);
        }
      }
    }
  }

  .subTitle + .myConnectionsTable {
    margin-top: 24px;
  }
}
