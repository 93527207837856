
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .analysisDetail {
  position: absolute;
  z-index: $Z_INDEX_MAP_TOOL;
  top: 24px;
  left: 24px;
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 12px 0;
  border-radius: 8px;
  background: var(--bg-sub);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);

  .detail {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 2px 24px;
    gap: 8px;

    .label {
      @include body2-regular;
      @include one-line-ellipsis;
      width: 96px;
      color: var(--text-sub);
    }

    .data {
      @include body2-regular;
      @include one-line-ellipsis;
      width: 148px;

      > .highlight {
        color: var(--comp-primary);
      }
      > .sub {
        color: var(--text-sub);
      }
    }

    .error {
      color: var(--chip-red-text);
      background: var(--chip-red-container);
    }
  }
}
