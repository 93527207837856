
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .aiPackParagraph {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid transparent;

  > .title {
    display: flex;
    height: 32px;

    > img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }

    > span[role="img"] {
      font-size: 32px;
    }

    > p {
      @include one-line-ellipsis;
      @include heading1;
      margin-left: 12px;
      color: var(--text-main);
    }
  }

  > .desc {
    @include multi-line-ellipsis(6);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
    max-width: $MAX_WIDTH_PARAGRAPH;
    color: var(--text-sub);
  }

  > .img {
    width: 34.5vw;
    max-width: $MAX_WIDTH_PARAGRAPH;
    object-fit: cover;
  }

  > .title + .desc {
    margin-top: 20px;
  }
  > .desc + .img {
    margin-top: 40px;
  }
}

.selectable {
  padding: 32px;
  cursor: pointer;
  border-radius: 20px;
  background: var(--white-color);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}

.active {
  border: 1px solid var(--border-active);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);

  .title > p {
    color: var(--planet-blue-500);
  }
}

@include tablet-media-query {
  .aiPackParagraph {
    > .desc {
      @include multi-line-ellipsis(13);
    }
  }
}
@include mobile-media-query {
  .aiPackParagraph {
    > .title {
      > img {
        width: 28px;
        height: 28px;
      }
      > span[role="img"] {
        font-size: 28px;
      }
      > p {
        font-size: 22px;
      }
    }
    > .desc {
      @include multi-line-ellipsis(10);
    }

    > .img {
      width: 100%;
      max-width: 100%;
    }
  }
}