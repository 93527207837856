
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          main {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  > .scrollable {
    @include custom-scroll-bar;
    display: flex;
    overflow-x: auto;
    overflow-y: auto;
    align-items: center;
    flex-flow: column nowrap;
    width: 100%;
    height: calc(100vh - #{$HEIGHT_GNB});
    margin-top: $HEIGHT_GNB;
  }
  > .isPrivateDomains {
    height: 100vh;
    margin-top: 0;
  }
}

.protectedLayout {
  display: flex;
  width: 60.2vw;
  max-width: 1040px;
  padding-top: 80px;
  padding-bottom: 100px;

  > nav {
    margin-right: 48px;
  }
}

@include tablet-media-query {
  .protectedLayout {
    align-items: flex-start;
    flex-direction: column;
    width: 80vw;
    padding-top: 40px;

    nav {
      margin-right: 0;
      margin-bottom: 48px;
    }
  }
}
