
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .myImages {
  display: flex;
  flex-flow: column nowrap;
  width: $WIDTH_PANEL;
  height: 100%;

  .panel {
    display: flex;
    height: calc(100% - #{$HEIGHT_PANEL_PAGINATION});
    padding: 20px 0 16px 0;

    .search {
      padding: 0 24px 8px 24px;
    }

    > .filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      padding: 6px 24px;

      :global(.ods-dropdown) {
        width: 132px;
        margin-right: 2px;
        margin-left: 8px;
      }

      .sort {
        display: flex;
        align-items: center;

        span[role="img"] {
          color: var(--icon-default);
        }
      }

      .filterIcon {
        span[role="img"] {
          color: var(--icon-default);
        }
      }

      .filtered {
        background: transparent;
        svg {
          color: var(--planet-blue-500);
        }
      }
    }

    > .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      padding: 0 24px 0;

      > input {
        display: none;
      }

      > .iconBtn {
        span {
          color: var(--icon-default);
        }
      }
    }

    .imageList {
      @include custom-scroll-bar;
      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      flex-flow: column nowrap;
      width: 100%;
      height: calc(100% - #{$HEIGHT_PANEL_TITLE});
      padding: 0 24px 0;

      .loadingContainer {
        position: relative;
        display: flex;
        justify-content: center;
        height: 100%;

        .loading {
          position: absolute;
          top: calc(50% - 20px);
          display: flex;
        }
      }
    }
  }

  .panel.images {
    border-left: 1px solid var(--border-divider);
  }
  .panelPagination.images {
    border-left: 1px solid var(--border-divider);
  }
}
