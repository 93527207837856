
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .panel {
  display: flex;
  flex-flow: column nowrap;
  width: $WIDTH_PANEL;
  height: 100%;
  border-right: 1px solid var(--border-divider);
  background: var(--bg-main);
}
