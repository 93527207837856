
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $HEIGHT_GNB;
  padding: 0 8px;
  border-bottom: 1px solid var(--grey-200);
  background-color: var(--white-color);
  box-shadow: 0 4px 10px rgba(128, 149, 205, 0.05);

  .gnbMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: calc(#{$MAX_WIDTH_PARAGRAPH} * 2 + 88px);
    height: $HEIGHT_GNB;

    .left {
      display: flex;
      align-items: center;
      gap: 60px;

      .logoContainer {
        display: flex;
        align-items: center;
        gap: 8px;

        .logo {
          display: flex;
          height: 28px;
          object-fit: cover;
        }
        .logoMainText {
          font-size: 20px;
          font-weight: 600;
          font-style: normal;
          line-height: 24px; /* 120% */
          color: var(--text-main);
        }
        .logoSubText {
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          line-height: 24px; /* 150% */
          color: var(--text-main);
        }
      }

      nav {
        display: flex;
        gap: 48px;

        a {
          @include one-line-ellipsis;
          @include body1-medium;
          display: flex;
          align-items: center;
          height: 32px;
          text-decoration: none;
          color: var(--text-main);

          &:hover {
            color: var(--planet-blue-500);
          }

          span[role="img"] {
            font-size: 16px;
            color: var(--icon-secondary);
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 8px;

      .langBtn {
        display: flex;
        color: var(--text-sub);

        span[role="img"] {
          color: var(--icon-secondary);
        }
      }

      > .afterLogin {
        display: flex;
        align-items: center;

        > .divider {
          display: flex;
          width: 1px;
          height: 40px;
          margin-right: 8px;
          background-color: var(--border-divider);
        }

        > .creditBtn {
          display: flex;
          align-items: center;

          > span {
            display: flex;
            align-items: center;

            > .credit {
              @include body1-medium;
            }

            > span + span {
              margin-left: 8px;
            }

            > span[role="img"] {
              color: var(--icon-secondary);
            }
          }
        }

        > .profileContainer {
          position: relative;
          all: unset;

          > .profilePopover, :global(.ods-badge), :global(.ods-avatar) {
            display: flex;
            width: 36px;
            height: 36px;

            .profileMenu {
              top: 50px;
              right: initial;
            }
          }

          .profile {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@include tablet-media-query {
  header {
    .gnbMenuContainer {
      .left {
        gap: 30px;

        nav {
          gap: 30px;
        }
      }
      .right {
        .afterLogin {
          .profileContainer {
            .profilePopover {
              .profileMenu {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@include min-tablet-media-query {
  header {
    .gnbMenuContainer {
      gap: 4px;

      .left {
        .logoContainer {
          gap: 4px;

          .logo {
            height: 24px;
          }
        }
        nav {
          display: none;
          gap: 4px;
        }
      }
      .right {
        gap: 4px;

        .afterLogin {
          .divider, .creditBtn {
            display: none;
          }
        }

        button {
          height: var(--comp-small);
        }
      }
    }
  }
}