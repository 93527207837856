
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .gnb {
  position: fixed;
  z-index: $Z_INDEX_GNB;
  top: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: $HEIGHT_GNB;
}
