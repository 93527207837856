
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .geoAiPack {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 4507px;

  scroll-behavior: smooth;

  .visual {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    max-width: calc(#{$MAX_WIDTH_PARAGRAPH} * 2 + 88px + 58px);
    margin-top: 96px;
    margin-left: 58px;

    .slogan {
      z-index: 1;
      margin-top: -70px;

      > h1 {
        font-size: 60px;
        font-weight: 900;
        font-style: normal;
        line-height: 76px;
        width: 709px;
        max-width: 41vw;
        white-space: pre-line;
        color: var(--text-main);
      }
      > p {
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        line-height: 30px;
        margin-top: 32px;
        white-space: pre-line;
        color: var(--grey-800);
      }

      > .btnWrapper {
        flex-flow: row nowrap;
        margin-top: 64px;

        .primaryBtn {
          width: 204px;
          height: 56px;
        }
        .secondBtn {
          min-width: 108px;
        }

        > button + button {
          margin-left: 8px;
        }
      }
    }

    .radarGlobeWrapper {
      position: relative;
      overflow: hidden;
      height: 692px;
      margin-left: -125px;

      .radarGlobe {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .radarEffect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .radarObjects {
        position: absolute;
        top: -10px;
        left: -25px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .radarEffect {
        left: 10px;
        padding: 1px 8px 1px 9px;
        animation: rotation 3s infinite linear;
      }

      @keyframes rotation {
        0% {
          transform: rotate(315deg);
        }
        100% {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .detectionBackground {
    position: absolute;
    top: 1636px;
    right: 0;
    left: calc(50% + 107px);
    width: 43.8vw;
    max-width: 756px;
    height: 728px;
    transform: rotate(-180deg);
    border: 1px solid var(--green-200);
    border-radius: 0 406px 406px 0;
    background: linear-gradient(95.78deg, var(--green-100) 9.6%, var(--earth-green-50) 100.12%);
  }

  .segmentationBackground {
    position: absolute;
    top: 3365px;
    right: calc(50% - 136px);
    width: 1000px;
    height: 512px;
    transform: rotate(-180deg);
    border-radius: 406.5px 0 0 406.5px;
    background: var(--grey-200);
  }

  > .aiPack {
    position: absolute;
    top: 896px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    .superEnhancement {
      position: absolute;
      top: 0;
      padding-top: 64px;
    }
    .detection {
      position: absolute;
      top: 794px;
      padding-top: 62px;
    }
    .segmentation {
      position: absolute;
      top: 2318px;
    }
    .changeDetection {
      position: absolute;
      top: 3161px;
    }

    .leftArea {
      right: calc(50% + 44px);
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      width: 34vw;
      max-width: $MAX_WIDTH_PARAGRAPH;

      > .leftTitleImg {
        position: absolute;
        top: 0;
        right: 63px;
        transition: 0.3s all ease-in-out;
      }

      > .rightTitleImg {
        position: absolute;
        top: 0;
        left: -121px;
        transition: 0.3s all ease-in-out;
      }
    }

    .rightArea {
      left: calc(50% + 44px);
      display: flex;
      flex-flow: column nowrap;
      width: 34vw;
      max-width: $MAX_WIDTH_PARAGRAPH;

      > .leftTitleImg {
        position: absolute;
        top: 0;
        left: -38px;
        transition: 0.3s all ease-in-out;
      }

      > .rightTitleImg {
        position: absolute;
        top: 0;
        left: 64px;
        transition: 0.3s all ease-in-out;
      }
    }

    .back {
      filter: blur(2px);
      scale: 0.9;
    }

    .front {
      z-index: 1;

      filter: blur(0px);
      scale: 1;
    }

    .aiPackTitle + .aiPackParagraph {
      margin-top: 48px;
    }
    .aiPackParagraph + .aiPackParagraph {
      margin-top: 24px;
    }

    .aircraftDetection + .vesselDetection {
      margin-top: 85px;
    }
    .vehicleDetection {
      margin-top: 739px;
    }
  }

  > .banner {
    position: absolute;
    top: 4887px;
    display: flex;
  }
}
@include tablet-media-query {
  .geoAiPack {
    .visual {
      margin: 96px 32px;

      .slogan {
        h1 {
          font-size: 44px;
          line-height: 58px;
          max-width: 56vw;
        }
        p {
          max-width: 60vw;
          white-space: normal;
        }
      }

      .radarGlobeWrapper {
        margin-left: -150px;

        .radarEffect {
          left: 8px;
        }
      }
    }
    .detectionBackground {
      display: none;
    }
    > .aiPack {
      .leftArea {
        right: calc(50% + 12px);
        width: 38vw;
      }
      .rightArea {
        left: calc(50% + 12px);
        width: 38vw;
      }
      .changeDetection {
        top: 3400px;
      }

      .vehicleDetection {
        margin-top: 759px;
      }
    }
    .banner {
      top: 4987px;
    }
  }
}
@include min-tablet-media-query {
  .geoAiPack {
    .visual {
      .slogan {
        h1 {
          font-size: 40px;
          line-height: 44px;
        }
        p {
          font-size: 18px;
          white-space: initial;
        }
      }
    }
  }
}
@include mobile-media-query {
  .geoAiPack {
    padding-bottom: 5307px;

    .visual {
      flex-flow: column nowrap;
      max-width: 100%;
      margin: 96px 16px;

      .slogan {
        margin-top: -20px;

        h1 {
          font-size: 30px;
          line-height: 36px;
          max-width: 82vw;
        }

        p {
          font-size: 16px;
          max-width: 82vw;
          white-space: initial;
        }

        > .btnWrapper {
          margin-top: 40px;

          .primaryBtn {
            width: fit-content;
            height: 52px;
          }
        }
      }

      .radarGlobeWrapper {
        height: 334px;
        margin-left: 0;
      }
    }

    .detectionBackground {
      display: none;
    }
    .segmentationBackground {
      display: none;
    }

    > .aiPack {
      .leftArea, .rightArea {
        right: 0;
        left: 0;
        justify-content: initial;
        width: 100%;
        max-width: 100%;
        padding: 0 8px;

        > .leftTitleImg {
          position: initial;
        }

        > .rightTitleImg {
          position: initial;
        }
      }

      .superEnhancement.rightArea {
        top: 324px; // 308px + 16px;
      }

      .detection {
        top: 848px; // 794px + 30px + 16;
      }

      .detection.leftArea {
        top: 1174px; // 794px + 30px + 308px + 16px;
      }

      .segmentation {
        top: 2969px; // 2318px + 308px + 308px + 35px;
      }

      .segmentation.rightArea {
        top: 3293px; // 2318px + 308px + 308px + 35px + 308px + 16px;
      }

      .changeDetection {
        top: 4160px; // 3161px + 308px + 308px + 308px + 75px;
      }

      .changeDetection.leftArea {
        top: 4484px; // 3161px + 308px + 308px + 308px + 75px + 308px + 18px;
      }

      .back {
        display: none;
      }


      .aiPackTitle + .aiPackParagraph {
        margin-top: 18px;
      }

      .aircraftDetection + .vesselDetection {
        margin-top: 40px;
      }

      .vehicleDetection {
        margin-top: 1232px; // 739px + 308px + 185px;
      }
    }

    > .banner {
      top: 5887px;
    }
  }
}