
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .aiPackTitle {
  display: flex;
  flex-flow: column nowrap;

  > .index {
    @include body1-medium;
    display: flex;
    margin-bottom: 0;
    color: var(--planet-blue-600);
  }

  > .title {
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
    line-height: 50px;
    display: flex;
    margin-bottom: 0;
    color: var(--text-main);

    > .chip {
      margin-left: 12px;
    }
  }

  > .desc {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 30px;
    display: flex;
    max-width: $MAX_WIDTH_PARAGRAPH;
    margin-bottom: 0;
    color: var(--grey-800);
  }

  > .index + .title {
    margin-top: 12px;
  }
  > .title + .desc {
    margin-top: 36px;
  }
}
@include mobile-media-query {
 .aiPackTitle {
   > .title {
     font-size: 24px;
     line-height: 30px;
   }
   > .desc {
     font-size: 10px;
     line-height: 14px;
   }

   > .index + .title {
     margin-top: 4px;
   }
 }
}