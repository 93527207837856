
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .filterPopover {
  position: absolute;
  z-index: $Z_INDEX_POPOVER;
  display: flex;
  flex-flow: column nowrap;
  width: $WIDTH_FILTER_POPOVER;
  padding: 16px 24px;
  border-radius: 8px;
  background: var(--bg-main);
  box-shadow: 0px 1px 3px 1px #00000026;

  > .bottom {
    position: relative;
    display: flex;
    align-self: flex-end;
    height: 28px;
    margin-top: 8px;
    padding: 0 4px;

    .resetBtn {
      position: absolute;
      right: -12px;
      bottom: -4px;
    }
  }
}
.leftArrow::after {
  position: absolute;
  top: 17px;
  left: -8px;
  width: 16px;
  height: 16px;
  content: "";
  transform: rotate(-45deg);
  background: var(--bg-main);
  box-shadow: -2px -2px 3px -1px #00000026;
}

.rightArrow::after {
  position: absolute;
  top: 17px;
  left: 280px;
  width: 16px;
  height: 16px;
  content: "";
  transform: rotate(-45deg);
  background: var(--bg-main);
  box-shadow: 2px 2px 3px -1px #00000026;
}

.divider {
  height: 1px;
  margin: 20px 0;
  border: none;
  background-color: var(--border-divider);
}
