
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .title {
  @include heading2;
  @include one-line-ellipsis;
  height: $HEIGHT_PANEL_TITLE;
  color: var(--text-main);
}
