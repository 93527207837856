
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .container {
  position: relative;

  .storageContainer {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-flow: column nowrap;
    width: 264px;
    height: 230px;
    padding-top: 28px;
    border: 1px solid var(--border-divider);
    border-radius: 8px;

    .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;

      > img {
        width: auto;
        height: auto;
      }
    }

    .storageName {
      @include heading3;
      margin-top: 8px;
    }
    .storageDescription {
      @include caption1-regular;
      width: 216px;
      height: 32px;
      margin-top: 8px;
      text-align: center;
      color: var(--text-guide);
    }
    .storageConnection {
      margin-top: 4px;
    }

    .edge {
      @include heading3;
      position: absolute;
      top: -22px;
      left: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25.48px;
      height: 87.68px;
      transform: rotate(45deg);
      color: var(--static-50);
      background: var(--moonlight-yellow-500);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);

      > span {
        transform: rotate(-95deg);
      }
    }
  }

  .connectionConfirmPopoverContainer {
    position: absolute;
    z-index: $Z_INDEX_POPOVER;
    bottom: 65px;
    left: 105px;
    width: 256px;
    height: 124px;
    padding: 12px 16px 16px;
    border-radius: 8px;
    background-color: var(--comp-container-50);

    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    .header {
      display: flex;
      .errorIconContainer {
        width: 18px;
        height: 60px;
        margin-right: 8px;
        .errorIcon {
          font-size: 18px;
          color: var(--semantic-warning);
        }
      }

      .textContainer {
        @include body2-regular;
        width: 198px;
        height: 60px;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      gap: 10px;
    }
  }

  .connectionConfirmPopoverContainer::after {
    position: absolute;
    bottom: -14px;
    content: "";
    border-top: 14px solid var(--comp-container-50);
    border-right: 14px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 14px solid transparent;
  }
}
