
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .creationInquiryModal {
  section {
    gap: 32px;

    > p {
      @include body2-regular;
      max-width: 536px;
      white-space: pre-line;
      color: var(--text-main);
    }

    > form {
      gap: 20px;

      .required {
        display: flex;
        width: 100%;

        &::after {
          @include caption1-medium;
          align-self: flex-start;
          margin-left: 4px;
          content: "*";
          color: var(--semantic-danger);
        }
      }

      .horizontal {
        display: flex;
        gap: 12px;
      }

      .checkboxGroup {
        display: flex;
        flex-flow: column nowrap;

        .checkRequired {
          @include caption1-regular;
          color: var(--semantic-danger);
        }

        span + .checkbox,
        .checkbox + span {
          margin-top: 8px;
        }
        .checkbox + .checkbox {
          margin-top: 12px;
        }
      }
    }
  }
}
