
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .myCredit {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-bottom: 300px;

  > .titleContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-bottom: 40px;

    > .title {
      @include heading1;
    }
    > .desc {
      @include body1-medium;
      color: var(--text-sub);
    }
  }
  .titleContainer.isSettingsMenu {
    > .title {
      @include heading2;
    }
  }

  .horizontal {
    display: flex;
    justify-content: space-between;
  }

  .userCreditContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;

    > .userCredits {
      font-size: 40px;
      font-weight: 600;
      font-style: normal;
      line-height: 32px;
    }
    > .label {
      @include body2-regular;
      align-self: flex-end;
      color: var(--text-sub);
    }
  }

  .tab {
    margin-top: 64px;

    .credit {
      @include body2-semi-bold;
    }

    .error {
      color: var(--chip-red-text);
      background: var(--chip-red-container);
    }

    .primary {
      @include body2-semi-bold;
      color: var(--comp-primary);
    }
    .cancel {
      color: var(--text-guide);
      text-decoration-line: line-through;
    }
    .semanticDanger {
      color: var(--semantic-danger);
    }
  }
}
