
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          main {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  > .scrollable {
    @include custom-scroll-bar;
    display: flex;
    overflow-x: auto;
    overflow-y: auto;
    align-items: center;
    flex-flow: column nowrap;
    width: 100%;
    height: calc(100vh - #{$HEIGHT_GNB});
    margin-top: $HEIGHT_GNB;
  }
}
