
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .aiPackTable {
  :global(.ant-table-thead
      th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
    display: none;
  }

  :global(td.ant-table-cell) {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: var(--text-main);
  }

  :global(thead.ant-table-thead th) {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: var(--text-main);
    border-bottom: 1px solid var(--grey-900);
  }

  :global(div.ant-table) {
    background-color: transparent;
  }

  :global(tr td) {
    height: 72px !important;
  }
  :global(tr:nth-last-child(1) td) {
    // @NOTE: for BDA
    height: 144px !important;
  }
}
