
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .resultExportPopover {
  position: absolute;
  z-index: $Z_INDEX_POPOVER;
  top: $HEIGHT_GNB;
  right: 16px;
  display: flex;
  flex-flow: column nowrap;
  width: 420px;
  border: 1px solid var(--border-divider);
  border-radius: var(--radius-medium);
  background: var(--bg-main);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  > .closeBtn {
    position: absolute;
    top: 14px;
    right: 25px;

    span[role="img"] {
      color: var(--icon-secondary);
    }
  }

  > .content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    margin-top: 8px;

    .titleContainer {
      display: flex;
      width: 100%;
      height: 24px;
      margin-top: 8px;

      .title {
        @include heading3;
        margin-left: 32px;
        color: var(--text-main);
      }
    }

    .link {
      display: flex;
      margin-top: 16px;

      :global(.ods-input-field-wrapper) {
        width: 320px;
      }

      .copyBtn {
        margin-left: 8px;
      }
    }
  }

  > .footer {
    display: flex;
    margin-top: 8px;
    padding: 8px 32px 24px;

    > .downloadBtn {
      width: 100%;
    }
  }
}
