
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .projectInfo {
  display: flex;
  height: 100%;
  width: calc(100% - 64px);

  .panelContainer {
    position: relative;
    display: flex;
    width: 384px;
    height: 100%;
    transition: margin-left 0.3s ease-in-out;
  
    .panel {
      display: flex;
      @include custom-scroll-bar;
      overflow-y: auto;
      justify-content: space-between;
      height: 100%;
      padding: 20px 0 16px 0;
  
      > .topContainer {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 24px 0;
  
        > .titleContainer {
          display: flex;
          align-items: center;
  
          .iconBtn {
            span[role="img"] {
              color: var(--icon-default);
            }
          }
          :global(.ods-tooltip-children-wrapper) {
            @include one-line-ellipsis;
          }
        }
  
        > .content {
          display: flex;
          flex-flow: column nowrap;
          gap: 24px;
  
          .infoContainer {
            @include body2-regular;
            display: flex;
            flex-flow: column nowrap;
            gap: 8px;
  
            .info {
              color: var(--text-sub);
            }
  
            .infoBox {
              display: flex;
              width: 100%;
              margin-top: 8px;
              padding: 12px;
              border: 1px solid var(--blue-200);
              border-radius: 8px;
              background: var(--blue-50);
              gap: 8px;
  
              > span[role="img"] {
                font-size: 18px;
                align-self: flex-start;
                color: var(--blue-500);
              }
              > p {
                @include body2-regular;
                white-space: pre-wrap;
              }
            }
          }
  
          .divider {
            display: flex;
            height: 1px;
            background: var(--border-divider);
          }
  
          .sourceItem {
            cursor: initial;
            border: 0;
            background: var(--comp-container-100) !important;
          }
  
          .grey {
            color: var(--text-sub);
            background: var(--comp-container-200);
          }
        }
  
        .titleContainer + .content {
          margin-top: 20px;
        }
      }
  
      > .bottomContainer {
        display: flex;
        align-items: center;
        padding: 0 24px 0;
  
        .saveBtn {
          display: flex;
          width: 100%;
        }
      }
    }
  }
}

.closed {
  margin-left: -$WIDTH_PANEL;
}
