
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .profileMenu {
  position: absolute;
  z-index: $Z_INDEX_POPOVER;
  top: var(--comp-medium);
  right: 0;
}
