
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .controlCard {
  position: absolute;
  z-index: $Z_INDEX_MAP_TOOL;
  bottom: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: var(--radius-medium);
  background: var(--bg-sub);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  gap: 16px;
}
