
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .infoHoverPopover {
  position: absolute;
  z-index: $Z_INDEX_POPOVER;
  left: $WIDTH_PANEL + 16px;
  display: flex;
  flex-flow: column nowrap;
  width: 356px;
  padding: 12px;
  border-radius: 8px;
  background: rgba(var(--white-color-rgb), 0.7);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  gap: 4px;

  > .title {
    @include caption1-semi-bold;
    color: var(--text-sub);
  }
  > img {
    width: 332px;
    height: 120px;
    border: 1px solid var(--border-divider);
    border-radius: 8px;
    object-fit: cover;
  }
}