
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .legal {
  display: flex;
  flex-flow: column nowrap;

  .label {
    @include heading3;
    color: var(--text-guide);
  }

  .title {
    font-size: 40px;
    font-weight: 800;
    font-style: normal;
    line-height: 40px;
    color: var(--text-main);
  }

  dl {
    display: flex;
    flex-flow: column nowrap;
    gap: 64px;

    .content {
      display: flex;
      flex-flow: column nowrap;
    }

    dt {
      font-size: 28px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
      color: var(--text-main);
    }
    dd {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 28px;
      white-space: pre-wrap;
      color: var(--text-main);

      a,
      span {
        color: var(--comp-primary);
      }
      strong {
        font-weight: 700;
      }
    }
    dt + dd {
      margin-top: 16px;
    }
    dd + dd {
      margin-top: 16px;
    }
  }

  .label + .title {
    margin-top: 12px;
  }
  .title + dl {
    margin-top: 32px;
  }
}
