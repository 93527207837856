
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .imageFilter {
  top: 112px;
  right: calc(#{$WIDTH_PANEL} + 15px);

  .periodFilter {
    > :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .fieldLabel {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .statusField {
    display: flex;
    flex-direction: column;

    gap: 12px;
  }
}
