
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .legend {
  @include body2-regular;
  position: absolute;
  z-index: $Z_INDEX_MAP_TOOL;
  right: 24px;
  bottom: 24px;
  display: flex;
  min-width: 240px;
  padding: 12px 24px;
  color: var(--text-main);
  border-radius: var(--radius-medium);
  background: var(--bg-sub);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  gap: 8px;

  ul {
    width: 100%;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 28px;

    span {
      display: inline-block;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      .label {
        @include one-line-ellipsis;
        color: var(--text-sub);
      }
    }

    .count {
      @include body2-semi-bold;
      display: flex;
    }
  }
}
