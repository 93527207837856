
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .fileItems {
  display: flex;
  flex-flow: column nowrap;
  border: 2px solid var(--border-divider);
  border-radius: var(--radius-medium);

  > .files {
    display: flex;
    flex-flow: column nowrap;
    padding: 12px;
    gap: 16px;

    > .fileItem {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;

      > .desc {
        @include caption1-regular;
        @include one-line-ellipsis;
        color: var(--text-sub);
      }

      > .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        padding: 4px 8px;
        border-radius: var(--radius-xs);
        background: var(--comp-select);

        > .left {
          @include one-line-ellipsis;
          display: flex;
          align-items: center;
          gap: 8px;

          span[role="img"] {
            font-size: var(--icon-medium);
            color: var(--planet-blue-300);
          }

          :global(.ods-tooltip-children-wrapper) {
            @include one-line-ellipsis;
          }
          .label {
            @include body2-medium;
            @include one-line-ellipsis;
          }
        }

        span[role="img"] {
          color: var(--icon-secondary);
        }
      }
    }
  }

  .addButton {
    border-top: 2px solid var(--border-divider);
    border-radius: 0 0 var(--radius-small) var(--radius-small);
  }
}
