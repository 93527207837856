
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .panelTipContainer {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -32px;
  display: flex;
  width: 32px;
  height: 80px;
  cursor: pointer;
  transform: translateY(-50%);
  border-radius: 0 8px 8px 0;
  background: var(--bg-main);

  .panelTip {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0 8px 8px 0;

    span[role="img"] {
      font-size: 24px;
      color: var(--icon-secondary);
    }

    &:hover {
      background: var(--comp-hover-grey);

      span[role="img"] {
        color: var(--icon-default);
      }
    }
  }
}
