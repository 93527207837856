
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .banner {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 74.1vw;
  max-width: 1280px;
  height: 280px;
  padding: 0 8px;

  > .bannerBgImg {
    width: 100%;
    height: 100%;
  }

  > .title {
    @include one-line-ellipsis;
    font-size: 38px;
    font-weight: 700;
    font-style: normal;
    line-height: 46px;
    position: absolute;
    top: 60px;
    display: flex;
    color: var(--white-color);
  }
  > .desc {
    @include one-line-ellipsis;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
    position: absolute;
    display: flex;
    margin-top: 118px;
    color: var(--planet-blue-100);
  }
  > .bannerButton {
    position: absolute;
    top: 170px;
    width: 238px;
    color: var(--white-color);

    &:hover,
    &:active,
    &:focus {
      color: var(--white-color);
      border-color: var(--planet-blue-100);
    }
  }

  > .lightBulbImg {
    position: absolute;
    top: -20px;
    left: 148px;
    width: 107px;
  }
  > .briefCaseImg {
    position: absolute;
    top: 140px;
    left: 56px;
    width: 53px;
  }
  > .satelliteImg {
    position: absolute;
    top: 154px;
    left: 240px;
    width: 85px;
  }
  > .roundPushpinImg {
    position: absolute;
    top: 63px;
    right: 127px;
    width: 31px;
  }
  > .magnifyingGlassImg {
    position: absolute;
    top: 160px;
    right: 220px;
    width: 73px;
  }
  > .yellowCircle {
    position: absolute;
    top: 210px;
    right: 66px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: radial-gradient(70.87% 70.87% at 32.56% 18.6%, var(--moonlight-yellow-500) 0%, #d19f1f 67.26%, #8f6805 100%);
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.1);
  }
}

@include tablet-media-query {
  .banner {
    width: 100%;
    max-width: 100%;
  }
}
@include mobile-media-query {
  .banner {
    width: 100%;
    max-width: 100%;

    > .bannerBgImg {
      border-radius: 16px;
    }
    > .title {
      font-size: 30px;
      top: 36px;
      text-align: center;
      white-space: pre-wrap;
    }
    > .desc {
      margin-top: 128px;
      text-align: center;
      white-space: pre-wrap;
    }
    > .bannerButton {
      top: 200px;
    }

    .lightBulbImg {
      top: -40px;
      left: 158px;
      width: 87px;
    }
    .briefCaseImg {
      display: none;
    }
    .satelliteImg {
      left: 30px;
    }
    .roundPushpinImg {
      top: 70px;
    }
    .magnifyingGlassImg {
      right: 50px;
    }
    .yellowCircle {
      right: 20px;
    }
  }
}