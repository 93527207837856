
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .verticalPlan {
  display: flex;
  width: 1020px;
  height: 120px;
  border: 1px solid var(--border-divider);
  border-radius: 8px;

  .highlight {
    width: 8px;
    height: 100%;
    border-radius: var(--radius-medium) 0 0 var(--radius-medium);
    background: var(--comp-primary);
  }
  .content {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px 40px 30px 32px;

    .creditContainer {
      display: flex;
      align-items: center;
      width: 240px;
      height: 60px;
      padding-left: 10px;
      gap: 8px;

      .amount {
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        line-height: 32px;
        letter-spacing: -0.02em;
      }
      .label {
        @include body2-semi-bold;
        color: var(--text-sub);
      }
    }
    .desc {
      @include body1-regular;

      strong {
        @include body1-semi-bold;
        letter-spacing: -0.02em;
      }
    }
    .priceContainer {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 12px;

      button {
        width: 120px;
      }
    }

    .creditContainer + .desc {
      margin-left: 120px;
    }
  }
}

.verticalPlan.welcomePlan {
  position: relative;
  background: var(--comp-primary);

  .creditContainer {
    width: 200px;
    border: 1px solid var(--border-divider);
    border-radius: var(--radius-medium);
    background: var(--bg-main);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    img {
      @keyframes rotate-animation {
        from {
          transform: rotateY(45deg);
        }
        to {
          transform: rotateY(225deg);
        }
      }
      animation: rotate-animation 1s linear infinite;
    }
  }
  .partyPopper {
    position: absolute;
    z-index: 1;
    top: -45px;
    left: 20px;
  }
  .desc {
    color: var(--static-50); // suggested
  }
  .priceContainer {
    button {
      border: 1px solid var(--static-50);

      span {
        @include body1-semi-bold;
        position: initial;
        color: var(--static-50);
      }
      &:hover {
        border: 1px solid var(--static-50);
        background: var(--planet-blue-600);
      }
    }
    .saleSlash {
      position: absolute;
      top: 18px;
      width: 36px;
      height: 2px;
      transform: rotate(150deg);
      background: var(--semantic-danger);
    }
  }

  .creditContainer + .desc {
    margin-left: 160px;
  }
}

.verticalPlan.isSettingsMenu {
  width: 800px;

  .content {
    padding: 30px 36px 30px 28px;

    .creditContainer + .desc {
      margin-left: 4px;
    }
  }
}

@include tablet-media-query {
  .verticalPlan {
    width: 100%;
    height: 176px;

    .content {
      flex-direction: column;

      .creditContainer + .desc {
        margin-left: 0;
      }
    }
  }
}
