
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .analysisContainer {
  display: flex;
  height: 100%;
  width: calc(100% - 64px);

  .analysis {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: $WIDTH_PANEL;
    height: 100%;
    transition: margin-left 0.3s ease-in-out;
  
    .panel {
      display: flex;
      height: calc(100% - #{$HEIGHT_PANEL_PAGINATION});
      padding: 0 0 16px 0;
  
      .headerPanel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 24px 12px 24px;
      }
  
      .search {
        padding: 8px 24px;
      }
  
      .panelToolBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 24px;
  
        span[role="img"] {
          color: var(--icon-default);
        }
  
        .sort {
          display: flex;
          align-items: center;
  
          :global(.ods-dropdown) {
            width: 132px;
            margin: 0 2px 0 8px;
          }
        }
        .filter.active {
          span[role="img"] {
            color: var(--planet-blue-500);
          }
        }
      }
  
      .projectList {
        @include custom-scroll-bar;
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
        flex-flow: column nowrap;
        width: 100%;
        height: calc(100% - #{$HEIGHT_PANEL_TITLE});
        padding: 0 24px 0;
  
        .loadingContainer {
          position: relative;
          display: flex;
          justify-content: center;
          height: 100%;
  
          .loading {
            position: absolute;
            top: calc(50% - 20px);
            display: flex;
          }
        }
      }
    }
  }
}

.analysis.closed {
  margin-left: -$WIDTH_PANEL;
}
