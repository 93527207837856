
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          :root {
  --optional: "(Optional)"; // InputField의 label에 사용
  --superx: "SuperX"; // Swipe의 label에 사용

  --chip-red-container: var(--chip-inc-container);

  &[data-theme~="light"] {
    --chip-red-text: var(--red-700);
  }
  &[data-theme~="dark"] {
    --chip-red-text: var(--red-400);
  }
}

.ods-form-item {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.ods-input-field-wrapper {
  width: 100%;
}
.ods-text-area-wrapper {
  width: 100%;
}
.ods-input-optional {
  display: flex;
  width: 100%;

  &::after {
    @include caption1-medium;
    align-self: flex-start;
    margin-left: 2px;
    content: var(--optional);
    color: var(--text-guide);
  }
}
.ods-radio {
  height: 24px;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(
    to right bottom,
    rgba(var(--black-color-rgb), 0.7),
    rgba(var(--black-color-rgb), 0.7)
  ) !important;
}
.ant-tooltip-inner {
  background-color: rgba(var(--black-color-rgb), 0.7) !important;
}

.ant-input-affix-wrapper-readonly {
  &:hover,
  &:focus-within {
    border: 1px solid var(--border-default) !important;
    box-shadow: none !important;
  }
  .ant-input-suffix {
    display: none !important;
  }
}

.ant-table {
  background: transparent;
}
.ant-table-body {
  @include custom-scroll-bar;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 12px;
  background: transparent;
}

.ant-table-thead > tr > th {
  @include caption1-medium;
  height: 28px;
  color: var(--text-sub);
}
.ant-table-thead > tr > th::before {
  width: 0 !important;
}

.ant-table-tbody > tr > td {
  @include body2-regular;
  height: 80px;
  color: var(--text-sub);
}
.ant-table-tbody > tr.ant-table-placeholder, .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  height: 160px;
  background: transparent;
}

.ant-table-pagination.ant-pagination {
  margin: 7px 0;
}
.ant-pagination-item {
  @include body2-regular;
  min-width: 20px;
  height: 20px;
  color: var(--text-sub);
}

.ant-pagination-item:hover a {
  color: var(--icon-active);
}
.ant-pagination-item-active, .ant-pagination-item-active:hover, .ant-pagination-item-active a, .ant-pagination-item-active:hover a {
  @include body2-semi-bold;
  line-height: 22px;
  color: var(--icon-active);
  border-color: transparent;
  background: transparent;
}
.ant-pagination-prev, .ant-pagination-next {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-default);
  border: 1px solid var(--border-default);
  border-radius: 6px;
}
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--icon-active);
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--icon-active);
}
.ant-pagination-disabled, .ant-pagination-disabled:hover, .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: var(--icon-disabled);
}

.ant-tabs-tab:hover {
  color: var(--comp-primary);
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid var(--comp-primary);
}
.ant-tabs-tab-btn {
  @include body1-regular;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @include body1-semi-bold;
  color: var(--comp-primary);
  text-shadow: none;
}