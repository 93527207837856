
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .searchContainer {
  position: absolute;
  z-index: $Z_INDEX_MAP_TOOL;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 360px;
  height: 44px;
  margin-top: 24px;
  margin-left: 24px;
  border-radius: 8px;
  background-color: var(--comp-container-50);
  box-shadow: 0 4px 8px rgba(153, 153, 153, 0.15);

  svg {
    width: var(--icon-medium);
    height: var(--icon-medium);
    color: var(--icon-default);
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;
    padding: 12px;
    gap: 8px;
  }

  .searchResult {
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: var(--comp-container-50);

    padding-block: 8px;

    .listItem {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 8px 16px;
      cursor: pointer;

      .locationOutlined {
        svg {
          color: var(--icon-secondary);
        }
      }

      .title {
        @include body2-regular;
        @include one-line-ellipsis;
        display: block;
        flex-shrink: 0;
        width: auto;
        max-width: calc(100% - 28px);
        margin-left: 12px;
        color: var(--text-main);
      }

      .subtext {
        @include caption1-regular;
        @include one-line-ellipsis;
        display: block;
        width: auto;
        margin-left: 12px;
        color: var(--text-guide);
      }
    }

    .hovered {
      background-color: var(--comp-hover-blue);
    }

    .selected {
      background-color: var(--comp-select);
    }

    .removeHoverEffect {
      pointer-events: none;
    }
  }

  input {
    @include body2-regular;
    width: 100%;
    color: var(--text-guide);
    border: 0;
    outline: 0;
    background: transparent;
  }

  &:focus-within {
    border-bottom: 1px solid var(--border-active);
    border-radius: var(--radius-medium) var(--radius-medium) 0 0;
    input {
      color: var(--text-main);
    }
  }
}
