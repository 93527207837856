
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .paymentResult {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  .paymentIcon {
    font-size: 91px;
    margin-top: 96px;
  }
  .successIcon {
    color: var(--semantic-positive);
  }
  .failIcon {
    color: var(--semantic-warning);
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    line-height: 32px;
    margin-top: 56px;
  }
  .desc {
    @include body1-regular;
    text-align: center;
    white-space: pre-wrap;

    a {
      @include body1-semi-bold;
      color: var(--comp-primary);
    }
  }
  .title + .desc {
    margin-top: 32px;
  }
  .desc + .desc {
    margin-top: 8px;
  }

  .section {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 480px;
    margin-top: 48px;
    padding: 32px 40px;
    border-radius: 12px;
    background: var(--white-color);

    .horizontal {
      display: flex;
      width: 100%;
      gap: 16px;
    }
    .vertical {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      gap: 8px;
    }
    .label {
      @include body1-regular;
      @include one-line-ellipsis;
    }
    .data {
      @include body1-semi-bold;
    }

    .horizontal .label {
      width: 104px;
    }
    .horizontal .data {
      @include one-line-ellipsis;
      width: calc(100% - 104px - 16px);
    }
    .vertical .data {
      word-wrap: break-word;
    }
    .horizontal + .horizontal {
      margin-top: 8px;
    }
    .vertical + .vertical {
      margin-top: 24px;
    }
  }
  .primary {
    color: var(--comp-primary);
  }
  button {
    width: 480px;
    margin-top: 32px;
  }
}