
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .content {
  display: flex;
  flex-flow: column nowrap;

  .subTitle {
    @include heading3;
    display: flex;
    color: var(--text-main);
  }

  .desc {
    @include body2-regular;
    color: var(--text-guide);

    a {
      color: var(--icon-active);

      text-decoration-line: underline;
    }
  }

  .cloudStoragesContainer {
    display: grid;
    gap: 24px;

    grid-template-columns: repeat(3, 1fr);
  }

  .formContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 400px;
    gap: 32px;

    .form {
      display: flex;
      flex-flow: column nowrap;
      gap: 24px;

      .inputLabel {
        @include body2-regular;
      }
      input::placeholder,
      textarea::placeholder {
        color: var(--text-guide);
      }
    }

    .connectBtn {
      align-self: flex-end;
      width: 88px;
    }
  }

  .subTitle + .desc {
    margin-top: 8px;
  }

  .subTitle + .cloudStoragesContainer {
    margin-top: 24px;
  }

  .myConnections + .subTitle {
    margin-top: 64px;
  }

  .desc + .formContainer {
    margin-top: 32px;
  }
}
