
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .sourceItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 68px;
  padding: 12px 8px 12px 16px;
  border: 2px solid var(--border-divider);
  border-radius: var(--radius-medium);

  > .left {
    display: flex;

    .thumbnail {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: var(--radius-medium);
    }

    .contents {
      display: flex;
      flex-flow: column nowrap;

      > .title {
        @include body2-semi-bold;
        @include one-line-ellipsis;
        width: 200px;
        color: var(--text-main);
      }

      > .description {
        @include body2-regular;
        @include one-line-ellipsis;
        width: 200px;
        color: var(--text-sub);
      }

      .title + .description {
        margin-top: 4px;
      }
    }

    .thumbnail + .contents {
      margin-left: 12px;
    }
  }

  > .right {
    display: flex;

    span[role="img"] {
      font-size: 24px;
      color: var(--icon-secondary);
    }
  }

  .dropdown {
    position: absolute;
    top: calc(50% - 14px);
    right: 16px;
    width: 124px;
    border-color: var(--border-divider);
  }

  &:hover {
    cursor: pointer;
    border: 2px solid var(--border-active);
  }
}

.active {
  border: 2px solid var(--border-active);
}
.disabled,
.disabled:hover {
  cursor: not-allowed;
  border: 2px solid var(--border-divider);
  background: var(--comp-container-200);

  .contents span {
    color: var(--text-disabled) !important;
  }
}
